<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('fertilizerReport.transport_program_management_report') }}</h4>
        </template>
      <template v-slot:body>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
          <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset">
            <b-row>
                <b-col xs="12" sm="12" md="6">
                  <ValidationProvider name="Program No" vid="program" rules="required">
                    <b-form-group
                      class="row"
                      label-cols-sm="3"
                      label-for="program"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('movement.program_no')}}  <span class="text-danger">*</span>
                      </template>
                      <b-form-input
                          id="program_no"
                          v-model="search.program_name"
                          :state="errors[0] ? false : (valid ? true : null)"
                          :placeholder="$t('movement.program_no')"
                      ></b-form-input>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="Vessels Name" vid="ship_name">
                  <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="ship_name"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('movement.vesselsName')}}
                        </template>
                        <b-form-input
                            id="ship_name"
                            v-model="search.ship_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('movement.vesselsName')"
                             v-if="i18 === 'en'"
                            readonly
                        ></b-form-input>
                        <b-form-input
                          id="ship_name"
                          v-model="search.ship_name_bn"
                          :placeholder="$t('movement.vesselsName')"
                          v-else
                          readonly
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-overlay :show='loadingData'>
            <b-row>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="Date" vid="date">
                  <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="date"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('globalTrans.date')}}
                        </template>
                        <b-form-input
                            id="date"
                            v-model="search.date"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('globalTrans.date')"
                            readonly
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="Fertilizer Name" vid="fertilizer_name">
                  <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="fertilizer_name"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('movement.fertilizer_name')}}
                        </template>
                        <b-form-input
                            id="date"
                            v-model="search.fertilizer_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('fertilizerConfig.port_name')"
                             v-if="i18 === 'en'"
                            readonly
                        ></b-form-input>
                        <b-form-input
                            id="date"
                            v-model="search.fertilizer_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('fertilizerConfig.port_name')"
                             v-else
                            readonly
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
            <b-row>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="port" vid="port">
                  <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="port"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('movement.port')}}
                        </template>
                        <b-form-input
                            id="date"
                            v-model="search.port_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('fertilizerConfig.port_name')"
                             v-if="i18 === 'en'"
                            readonly
                        ></b-form-input>
                        <b-form-input
                            id="date"
                            v-model="search.port_name_bn"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('fertilizerConfig.port_name')"
                             v-else
                            readonly
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="Fertilizer Allocated Amount" vid="FertilizerAllocatedAmount">
                  <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="FertilizerAllocatedAmount"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('fertilizerReport.FertilizerAllocatedAmount')}}
                        </template>
                        <b-form-input
                            id="FertilizerAllocatedAmount"
                            v-model="search.fertilizer_allocated_amount"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('fertilizerReport.FertilizerAllocatedAmount')"
                            readonly
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
             <b-row>
              <b-col xs="12" sm="12" md="6">
                <ValidationProvider name="Fertilizer Total Amount" vid="FertilizerTotalAmount">
                  <b-form-group
                        class="row"
                        label-cols-sm="3"
                        label-for="FertilizerTotalAmount"
                        slot-scope="{ valid, errors }"
                      >
                        <template v-slot:label>
                          {{$t('fertilizerReport.FertilizerTotalAmount')}}
                        </template>
                        <b-form-input
                            id="date"
                            v-model="search.fertilizer_total_amount"
                            :state="errors[0] ? false : (valid ? true : null)"
                            :placeholder="$t('fertilizerReport.FertilizerTotalAmount')"
                            readonly
                        ></b-form-input>
                        <div class="invalid-feedback">
                          {{ errors[0] }}
                        </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col xs="12" sm="12" md="6">
                  <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
            </b-overlay>
           </b-form>
        </ValidationObserver>
      </template>
    </iq-card>
      <b-row >
        <b-col md="12" v-if="showData">
          <b-overlay :show='loading'>
            <iq-card v-if='datas.length'>
              <template v-slot:body>
                <b-row>
                  <b-col md="12">
                    <iq-card>
                      <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('fertilizerReport.transport_program_management_report') }}</h4>
                      </template>
                      <template v-slot:headerAction>
                        <b-button class="btn_add_new" @click="pdfExport">
                          <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                        </b-button>
                      </template>
                      <template v-slot:body>
                        <b-overlay>
                          <b-row mt-5>
                            <b-col md="12" class="table-responsive">
                              <div style="border: 2px solid;margin:10px;padding:10px">
                                <b-row>
                                  <b-col>
                                    <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                                      <u>{{ $t('fertilizerReport.transport_program_management_report') }}</u>
                                    </list-report-head>
                                    <div class="text-center">
                                      <table style="width:100%;color:black;margin:20px;">
                                        <tr>
                                          <td align="left" style="width:20%">{{ $t('movement.program_no') }}</td>
                                          <td align="center" style="width:5%">:</td>
                                          <td align="left" style="width:25%">{{ $n(search.program_name) }}</td>
                                          <td align="left" style="width:20%">{{ $t('globalTrans.date') }}</td>
                                          <td style="width:5%">:</td>
                                          <td align="left" style="width:25%">{{ search.date | dateFormat }}</td>
                                        </tr>
                                        <tr>
                                          <td align="left">{{ $t('movement.vesselsName') }}</td>
                                          <td align="center" style="width:5%">:</td>
                                          <td align="left">{{ ($i18n.locale==='bn') ? search.ship_name_bn : search.ship_name }}</td>
                                          <td align="left">{{ $t('movement.port') }}</td>
                                          <td align="center" style="width:5%">:</td>
                                          <td align="left">{{ ($i18n.locale==='bn') ? search.port_name_bn : search.port_name }}</td>
                                        </tr>
                                        <tr>
                                          <td align="left">{{ $t('fertilizerReport.FertilizerTotalAmount') }}</td>
                                          <td align="center" style="width:5%">:</td>
                                          <td align="left">{{ $n(search.fertilizer_total_amount) }}</td>
                                          <td align="left">{{ $t('movement.fertilizer_name') }} </td>
                                          <td align="center" style="width:5%">:</td>
                                          <td align="left">{{ ($i18n.locale==='bn') ? search.fertilizer_name_bn : search.fertilizer_name }}</td>
                                        </tr>
                                        <tr>
                                          <td align="left">{{ $t('fertilizerReport.FertilizerAllocatedAmount') }} </td>
                                          <td  align="center" style="width:5%">:</td>
                                          <td align="left">{{ $n(search.fertilizer_allocated_amount) }}</td>
                                          <td align="left"></td>
                                          <td align="center" style="width:5%"></td>
                                          <td align="left"></td>
                                        </tr>
                                      </table>
                                    </div>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <div class="col text-right">
                                    <span class="text-right text-dark font-weight-bold"> {{ $t('fertilizerReport.accountMayTons') }}</span>
                                  </div>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <b-table-simple class="tg" style="" hover bordered small caption-top>
                                      <b-thead>
                                        <b-tr>
                                          <b-td class="tg-0lax">{{ $t('globalTrans.sl_no') }}</b-td>
                                          <b-td class="tg-0lax">{{ $t('fertilizerReport.region_name') }} </b-td>
                                          <b-td class="tg-0lax">{{ $t('fertilizerReport.receiver_godown') }} </b-td>
                                          <b-td class="tg-0lax">{{ $t('fertilizerReport.amount_of_fertilizer') }} </b-td>
                                        </b-tr>
                                      </b-thead>
                                      <b-tbody>
                                        <template v-for="(data, index) in datas">
                                          <b-tr :key="index">
                                            <b-td :rowspan="data.length + 1" class="tg-0lax">{{ $n(index + 1) }}</b-td>
                                            <b-td :rowspan="data.length + 1" class="tg-0lax">{{ getOfficeName(data[0].region_id) }}</b-td>
                                          </b-tr>
                                          <b-tr v-for="(agent, index1) in data" :key="data[0].region_id + 'index_' + index1">
                                            <b-td class="tg-0lax">{{ i18 === 'bn' ? agent.name_bn : agent.name }}</b-td>
                                            <b-td class="tg-0lax">{{ $n(agent.total_amount) }}</b-td>
                                          </b-tr>
                                        </template>
                                        <b-tr>
                                          <b-th class="tg-0lax text-right" colspan="3">{{ $t('fertilizerReport.GrandTotalMTon') }} </b-th>
                                          <b-th class="tg-0lax">
                                            {{ $n(grandTotal) }}
                                          </b-th>
                                        </b-tr>
                                      </b-tbody>
                                    </b-table-simple>
                                  </b-col>
                                </b-row>
                              </div>
                            </b-col>
                          </b-row>
                        </b-overlay>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
            <div class="panel-body text-center mt-3" v-else>
              <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
  </b-container>
</template>
<style type="text/css">
.tg  {border-collapse:collapse;border-spacing:0;}
.tg td{text-align: center !important; vertical-align: middle !important;}
.tg th{text-align: center !important; vertical-align: middle !important;}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, transportProgramManageReportGodownWise, ProgramNoSerach, portwiseAllocation } from '../../api/routes'
import ListReportHead from '.././ListReportHead.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ExportPdf from './export-pdf_details'
import flatpickr from 'flatpickr'

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead,
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      loadingData: false,
      showData: false,
      search: {
        program_name: '',
        fertilizer_allocated_amount: 0,
        fertilizer_total_amount: 0,
        date: ''
      },
      datas: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      grandTotal: 0
    }
  },
  computed: {
    i18 () {
      return this.$i18n.locale
    },
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    portList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.portInfoSetupList.filter(item => item.status === 1)
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
    core.index()
  },
  watch: {
    'search.program_name': function () {
      this.datas = []
      this.getProgramInfo()
    }
  },
  methods: {
    async getProgramInfo () {
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, ProgramNoSerach, this.search)
      if (!result.success) {
        this.search.lc_no = null
        this.search.port_id = null
        this.search.cnf_agent_id = null
        this.search.date = null
        this.search.ship_name = null
        this.search.ship_name_bn = null
        this.search.fertilizer_total_amount = null
        this.search.fertilizer_allocated_amount = null
        this.search.fertilizer_name = null
        this.search.fertilizer_name_bn = null
        this.search.port_name = null
        this.search.port_name_bn = null
      } else {
        const fartilizerObj = this.fertilizerNameList.find(item => item.value === result.data.fertilizer_id)
        const portObj = this.portList.find(item => item.value === result.data.port_id)
        this.search.lc_no = result.data.lc_no
        this.search.port_id = result.data.port_id
        this.search.cnf_agent_id = result.data.cnf_agent_id
        this.search.date = result.data.allocation_date
        this.search.ship_name = result.data.ship_name
        this.search.ship_name_bn = result.data.ship_name_bn
        this.search.fertilizer_total_amount = result.data.quantity
        this.search.fertilizer_allocated_amount = result.data.total_amount
        // this.getAllocation()
        this.search.fertilizer_name = typeof fartilizerObj !== 'undefined' ? fartilizerObj.text_en : ''
        this.search.fertilizer_name_bn = typeof fartilizerObj !== 'undefined' ? fartilizerObj.text_bn : ''
        this.search.port_name = typeof portObj !== 'undefined' ? portObj.text_en : ''
        this.search.port_name_bn = typeof portObj !== 'undefined' ? portObj.text_bn : ''
      }
    },
    async getAllocation () {
      const search = {
        lc_no: this.search.lc_no,
        port_id: this.search.port_id,
        cnf_agent_id: this.search.cnf_agent_id
      }
      this.loadingData = true
      const result = await RestApi.postData(seedFertilizerServiceBaseUrl, portwiseAllocation, search)
      if (result.success) {
        this.search.fertilizer_allocated_amount = result.data
      }
      this.loadingData = false
    },
    calculateTotal (data) {
      let total = 0
      data.forEach((element, key) => {
        element.forEach((element1, key) => {
          total += element1.total_amount
        })
      })
      this.grandTotal += total
    },
    async register () {
      this.showData = true
      this.loadData()
    },
    async loadData () {
      this.loading = true
        await RestApi.getData(seedFertilizerServiceBaseUrl, transportProgramManageReportGodownWise, { program_no: this.search.program_name }).then(response => {
        if (response.success) {
          if (response.data) {
            this.datas = response.data
            this.calculateTotal(response.data)
            this.showData = true
          }
        } else {
          this.datas = []
        }
      })
      this.loading = false
    },
    pdfExport () {
      const reportTitle = this.$t('fertilizerReport.transport_program_management_report')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.datas, this, this.search)
    },
    getOfficeName (Id) {
      const office = this.$store.state.commonObj.officeList.find(item => item.value === parseInt(Id))
      if (typeof office === 'undefined') {
        return ''
      } else {
        return office.text
      }
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
 .row-active {
    color: #212529;
    /* background-color: rgba(0, 0, 0, 0.075); */
    border: 1px solid black !important;
 }
</style>
