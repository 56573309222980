import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

function getOfficeName (Id) {
    const office = Store.state.commonObj.officeList.find(item => item.value === parseInt(Id))
    if (typeof office === 'undefined') {
        return ''
    } else {
        return office.text
    }
}
function calculateTotal (data) {
    let grandtotal = 0
    data.map(item => {
        const total = item.map(r => r.total_amount).reduce((a, c) => {
            a = a ? parseInt(a) : 0
            c = c ? parseInt(c) : 0
            return a + c
        })
        grandtotal += parseInt(total)
        return false
    })
    return grandtotal
}

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, dataArray, vm, search) => {
    try {
        Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            columns: [
              { width: '*', text: '' },
              { width: '*', text: '' },
              { width: '*', text: vm.$t('fertilizerReport.krishi_bhaban') },
              { width: '*', text: '' },
              { width: '*', text: '' }
            ],
            style: 'krishi'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }

        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        const allRowsData = [
          [
            { text: vm.$t('movement.program_no'), style: 'th' },
            { text: ':', style: 'th', alignment: 'center' },
            { text: vm.$n(search.program_name), style: 'th' },
            { text: vm.$t('globalTrans.date'), style: 'th' },
            { text: ':', style: 'th', alignment: 'center' },
            { text: dateFormat(search.date), style: 'th' }
          ],
          [
            { text: vm.$t('movement.vesselsName'), style: 'th' },
            { text: ':', style: 'th', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.ship_name_bn : search.ship_name, style: 'th' },
            { text: vm.$t('fertilizerConfig.port_name'), style: 'th' },
            { text: ':', style: 'th', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.port_name_bn : search.port_name, style: 'th' }
          ],
          [
            { text: vm.$t('fertilizerReport.FertilizerTotalAmount'), style: 'th' },
            { text: ':', style: 'th', alignment: 'center' },
            { text: vm.$n(search.fertilizer_total_amount), style: 'th' },
            { text: vm.$t('movement.fertilizer_name'), style: 'th' },
            { text: ':', style: 'th', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? search.fertilizer_name_bn : search.fertilizer_name, style: 'th' }
          ],
          [
            { text: vm.$t('fertilizerReport.FertilizerAllocatedAmount'), style: 'th' },
            { text: ':', style: 'th', alignment: 'center' },
            { text: vm.$n(search.fertilizer_allocated_amount), style: 'th' },
            {},
            {},
            {}
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['20%', '5%', '25%', '20%', '5%', '25%'],
            body: allRowsData
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: vm.$t('fertilizerReport.accountMayTons'), style: 'fertilizer', alignment: 'right', bold: true })

        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerReport.region_name'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerReport.receiver_godown'), style: 'th', alignment: 'center' },
            { text: vm.$t('fertilizerReport.amount_of_fertilizer'), style: 'th', alignment: 'right' }
          ]
        ]
        dataArray.map((data, index) => {
            const len = data.length
          data.map((agent, index1) => {
            if (index1 === 0) {
                const singleRows = [
                    { text: vm.$n(index + 1), rowSpan: len, style: 'td', alignment: 'center' },
                    { text: getOfficeName(agent.region_id), rowSpan: len, style: 'td', alignment: 'center' },
                    { text: (i18n.locale === 'bn') ? agent.name_bn : agent.name, style: 'td', alignment: 'center' },
                    { text: vm.$n(agent.total_amount), style: 'td', alignment: 'right' }
                  ]
                allRows.push(singleRows)
            } else {
                const singleRows = [
                  {},
                  {},
                  { text: (i18n.locale === 'bn') ? agent.name_bn : agent.name, style: 'td', alignment: 'center' },
                  { text: vm.$n(agent.total_amount), style: 'td', alignment: 'right' }
                ]
                allRows.push(singleRows)
            }
          })
        })
        allRows.push(
            [
              { text: vm.$t('fertilizerReport.GrandTotalMTon'), style: 'th', colSpan: 3, alignment: 'right', bold: true },
              {},
              {},
              { text: vm.$n(calculateTotal(dataArray)), style: 'th', alignment: 'right', bold: true }
            ]
          )
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['8%', '37%', '25%', '30%'],
            body: allRows
          }
        })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: '',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [0, 10, 0, 10]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 15]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
            },
          report_type: {
            fontSize: 9,
            margin: [0, 0, 0, 15]
          },
          titleText: {
            fontSize: 9
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('transport-program-management-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
